import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import moment from 'moment';
export enum SEVERITY {
  MEDIUM,
  HIGH,
}
export interface Alarm extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  productName: string;
  storeName: string;
  timestamp: number;
  severity: SEVERITY;
  uuid: string;
}

export const AlarmCard = (alarm: Alarm) => {
  const {
    title,
    description,
    timestamp,
    storeName,
    productName,
    severity,
    uuid,
  } = alarm;

  const theme = useTheme();
  const color = severity
    ? theme.palette.error.main
    : theme.palette.warning.main;
  const backgroundColor = alpha(color, 0.1);
  return (
    <Card
      sx={{
        maxWidth: 220,
        borderRadius: '1.5rem',
        margin: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxShadow: 2,
      }}
    >
      <CardActionArea href={`/alarm/${alarm.uuid}`} sx={{ height: '100%' }}>
        <CardHeader
          sx={{
            backgroundColor: color,
            flex: '1 1',
            padding: '0.4rem 1rem',
          }}
          title={
            <Typography
              variant="h6"
              fontWeight="bold"
              color={(theme) => theme.palette.common.white}
            >
              {title}
            </Typography>
          }
        />
        <CardContent sx={{ padding: 0, height: '100%' }}>
          <Box
            display="flex"
            gap="0.5rem"
            padding={'1rem'}
            bgcolor={backgroundColor}
            justifyContent="start"
            alignItems="start"
            height={'100%'}
            flexDirection={'column'}
          >
            <Typography
              fontWeight={'600'}
              fontSize={'1rem'}
              lineHeight={1.2}
              color={color}
              margin={'0.5rem 0'}
            >
              {storeName}
            </Typography>
            <Typography
              fontWeight={'600'}
              fontSize={'1rem'}
              lineHeight={1.2}
              margin={'0.5rem 0'}
              color={color}
            >
              {description}
            </Typography>
            <Typography
              fontWeight={'600'}
              fontSize={'1rem'}
              lineHeight={1.2}
              margin={'0.5rem 0'}
              color={color}
            >
              {productName}
            </Typography>
            <Typography
              margin={'0.5rem 0'}
              fontSize={'1rem'}
              lineHeight={1.2}
              color={color}
            >
              {moment(timestamp).format('l LTS')}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AlarmCard;
