/* FIXME: lib not working, eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { getZipControlUnitsByDeviceIds } from '../selectors';
import { fetchDeviceControlUnits } from '../actions/thunks';
import { isEqual } from 'lodash';
import useDevicesByParams from '../../devices/hooks/useDevicesByParams';

/**
 * Get and fetch control unit from specified device
 * @param deviceId
 * @returns [controlUnits, loading, error]
 * 
 * @usage
´´´ts
const [controlUnits, loading, error] = useControlUnitsByIds(onError, { deviceIds: ['device1', 'device2'] });
´´´
 */
export function useControlUnitsByIds(
  onError?: (error: unknown) => unknown,
  options?: { deviceIds: string[] }
) {
  const { devicesIds, loading: devicesLoading } = useDevicesByParams(options);
  const devicesControlUnits = useAppSelector(
    (state: RootState) => getZipControlUnitsByDeviceIds(state, { devicesIds }),
    isEqual
  );
  const needFetch = devicesControlUnits.some(
    (deviceControlUnits) => deviceControlUnits.loading === undefined
  );
  const loading = devicesControlUnits.some(
    (deviceControlUnits) => deviceControlUnits.loading
  );
  const error = devicesControlUnits.some(
    (deviceControlUnits) => deviceControlUnits.error
  );

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (needFetch && !devicesLoading) {
      Promise.all(
        devicesControlUnits.map((deviceControlUnits) =>
          dispatch<any>(
            fetchDeviceControlUnits(deviceControlUnits.deviceId)
          ).unwrap()
        )
      ).catch(onError);
    }
  }, [needFetch, devicesLoading]);
  return [
    devicesControlUnits,
    loading || needFetch || devicesLoading,
    error,
  ] as const;
}
