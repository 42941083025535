import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { getAllChildrenGroups } from '../../../../../../redux/devices/selectors';
import { SCC_TYPE } from '../../../../redux/composableDevice/model/utils';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { getPolyglot } from '../../../../../../i18n';
import MultiSelectAutocomplete from '../../../../../../theme/components/Forms/MultiSelectAutocomplete';

const FilterPanel = (props: {
  regions: Group[];
  stores: Group[];
  merchandisers: Merchandiser[];
  timePeriods: string[];
  onFilterChange: (items: {
    regions: Group[];
    merchandisers: Merchandiser[];
    stores: Group[];
    timePeriod: string;
  }) => void;
}) => {
  const { regions, stores, merchandisers, timePeriods, onFilterChange } = props;

  const polyglot = getPolyglot();

  const [storesOptions, setStoresOptions] = React.useState<Group[]>(stores);
  const [merchandisersOptions, setMerchandisersOptions] =
    React.useState<Merchandiser[]>(merchandisers);
  const [filteredRegions, setFilteredRegions] = React.useState<Group[]>([]);
  const [filteredStores, setFilteredStores] = React.useState<Group[]>([]);
  const [filteredMerchandisers, setFilteredMerchandisers] = React.useState<
    Merchandiser[]
  >([]);

  const [selectedPeriod, setSelectedPeriod] = React.useState<string>(
    timePeriods[0]
  );

  const [clearSelectedOptions, setClearSelectedOptions] = React.useState(false);

  useEffect(() => {
    if (filteredRegions.length > 0) {
      const storesFromFilteredRegions = getAllChildrenGroups(
        filteredRegions,
        []
      ).filter((item) => item.attributes['SCC_TYPE'] === SCC_TYPE.STORE);

      setStoresOptions(storesFromFilteredRegions);
    } else {
      setStoresOptions(stores);
    }
    setFilteredStores([]);
  }, [filteredRegions]);

  useEffect(() => {
    if (filteredStores.length > 0 || storesOptions.length > 0) {
      const itemToFilter =
        filteredStores.length > 0 ? filteredStores : storesOptions;
      const childrenStores = getAllChildrenGroups(
        itemToFilter.map((store) => store.childGroups).flat(),
        []
      );
      const merchandisersFromStores = merchandisers.filter((merchandiser) =>
        childrenStores.find((store) => store.id === merchandiser.id)
      );
      setMerchandisersOptions(merchandisersFromStores);
    } else {
      setMerchandisersOptions([]);
    }
  }, [storesOptions, filteredStores]);

  const filterHandle = () => {
    onFilterChange({
      regions: filteredRegions.length > 0 ? filteredRegions : regions,
      stores: filteredStores.length > 0 ? filteredStores : storesOptions,
      merchandisers:
        filteredMerchandisers.length > 0
          ? filteredMerchandisers
          : merchandisersOptions,
      timePeriod: selectedPeriod,
    });
  };

  const onClearHandle = () => {
    setClearSelectedOptions(!clearSelectedOptions);
  };

  return (
    <Grid container sx={{ padding: '0 2rem' }} spacing={1}>
      <Grid item xs={9}>
        <Box display={'flex'} gap={1} flexWrap={'wrap'}>
          <FormControl fullWidth sx={{ flex: '1 0 10%' }}>
            <MultiSelectAutocomplete
              clearSelectedOptions={clearSelectedOptions}
              label=""
              placeholder={polyglot.t('freshwatch.dashboard.all_regions')}
              options={regions}
              onSelectionChange={setFilteredRegions}
              displayResultFn={(option) => option.name}
              labelFn={(option) => option.name}
              equalFn={(option, value) => option.id === value.id}
            />
          </FormControl>
          <FormControl fullWidth sx={{ flex: '1 0 10%' }}>
            <MultiSelectAutocomplete
              placeholder={polyglot.t('freshwatch.dashboard.all_stores')}
              clearSelectedOptions={clearSelectedOptions}
              label=""
              options={storesOptions}
              onSelectionChange={setFilteredStores}
              displayResultFn={(option) => option.name}
              labelFn={(option) => option.name}
              equalFn={(option, value) => option.id === value.id}
            />
          </FormControl>
          <FormControl fullWidth sx={{ flex: '1 0 10%' }}>
            <MultiSelectAutocomplete
              label=""
              placeholder={polyglot.t('freshwatch.dashboard.all_merchandisers')}
              clearSelectedOptions={clearSelectedOptions}
              options={merchandisersOptions}
              onSelectionChange={setFilteredMerchandisers}
              displayResultFn={(option) => option.name}
              labelFn={(option) => option.name}
              equalFn={(option, value) => option.id === value.id}
            />
          </FormControl>
          <FormControl fullWidth sx={{ flex: '1 0 10%' }}>
            <Select
              id="time-select"
              placeholder={selectedPeriod}
              value={selectedPeriod}
              onChange={(e: SelectChangeEvent) =>
                setSelectedPeriod(e?.target.value as string)
              }
            >
              {timePeriods.map((timePeriod) => (
                <MenuItem key={timePeriod} value={timePeriod}>
                  {timePeriod}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box
          display={'flex'}
          height={'100%'}
          gap={0.2}
          alignItems={'center'}
          justifyContent={'space-evenly'}
        >
          <Button
            color="primary"
            variant="outlined"
            type="button"
            sx={{ borderRadius: '1.5rem', fontSize: '0.8rem' }}
            fullWidth
            onClick={onClearHandle}
            size="small"
          >
            {polyglot.t('freshwatch.dashboard.clear')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="button"
            sx={{ borderRadius: '1.5rem', fontSize: '0.8rem' }}
            fullWidth
            size="small"
            onClick={filterHandle}
          >
            {polyglot.t('freshwatch.dashboard.ok')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterPanel;
