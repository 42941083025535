import React from 'react';
import { ContentPage } from './MerchandiserLayout';
import GroupSettingsPage from '../../../../components/DeviceManager/Group/GroupSettingsPage';

function MerchandiserSettings() {
  return (
    <ContentPage variant="elevation">
      <GroupSettingsPage />
    </ContentPage>
  );
}

export default MerchandiserSettings;
