export enum LocalStorageKeys {
  temperatureUnit = 'temperatureUnit',
}

/**
 * Save item in local storage
 * @param LocalStorageKeys key
 * @param string value
 */
export const setLocalStorageValue = (key: LocalStorageKeys, value: string) => {
  localStorage.setItem(key, value);
};

/**
 * Get the item saved in local storage
 * @param LocalStorageKeys key
 */
export const getLocalStorageValue = (key: LocalStorageKeys) => {
  return localStorage.getItem(key);
};
