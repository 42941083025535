import { Box, Grid, InputAdornment, Slider, TextField } from '@mui/material';
import React from 'react';
import { ParameterEditControlProps } from './ParameterEditControl';
import { getPolyglot } from '../../../../../../../i18n';
import { DEFAULT_DECIMALS } from '../../../../../../../redux/controlUnit/api/util/constants';
import {
  TemperatureUnit,
  formatNumber,
  getTemperature,
} from '../../../../../../../customized/fresh-watch/theme/utils/temperatureUtils';
import {
  LocalStorageKeys,
  getLocalStorageValue,
} from '../../../../../../../util/SharedUtils';

export default function ParameterEditControlSlider(
  props: ParameterEditControlProps
) {
  const { parameter, onChangeValue, value } = props;
  const polyglot = getPolyglot();

  const tempUnit = (getLocalStorageValue(LocalStorageKeys.temperatureUnit) ||
    parameter.unitString ||
    '°C') as TemperatureUnit;

  const numberValue = parseFloat(value);
  const minValue = getTemperature(parameter.min, tempUnit);
  const maxValue = getTemperature(parameter.max, tempUnit);

  function formatValue(value: number) {
    return `${formatNumber(
      value,
      parameter.decimals ?? DEFAULT_DECIMALS
    )}${tempUnit}`;
  }
  const onChangeSlider = (event: Event, value: number | number[]) => {
    onChangeValue(value.toString());
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '' ? 0 : Number(event.target.value);
    onChangeValue(value.toString());
  };

  const handleBlur = () => {
    if (numberValue < minValue) {
      onChangeValue(minValue.toString());
    } else if (numberValue > maxValue) {
      onChangeValue(maxValue.toString());
    }
  };

  const step = 1 / 10 ** parameter.decimals;
  const marks = [
    {
      value: minValue,
      label: formatValue(minValue),
    },
    {
      value: maxValue,
      label: formatValue(maxValue),
    },
  ];
  return (
    <Box
      className="ParameterEditControlSlider"
      sx={{ width: '100%' }}
      padding={2}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} alignItems="center" textAlign={'center'}>
          {
            <TextField
              value={value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              required
              InputProps={{
                endAdornment: tempUnit && (
                  <InputAdornment position="end">{tempUnit}</InputAdornment>
                ),
              }}
              inputProps={{
                step: step,
                min: minValue,
                max: maxValue,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          }
        </Grid>
        <Grid item xs={12}>
          <Slider
            aria-label={polyglot.t('device.parameters.values')}
            value={numberValue}
            onChange={onChangeSlider}
            getAriaValueText={formatValue}
            step={step}
            marks={marks}
            min={minValue}
            max={maxValue}
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
