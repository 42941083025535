import React from 'react';
import { InfoModal, InfoModalProps } from '../../../../../theme/components';
import type { IControlUnitDeviceOasis } from './ComposableDeviceFormOasis';
import {
  FormLabel,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { getPolyglot } from '../../../../../i18n';
import ControlUnitModel from '../../../../../redux/controlUnit/api/ControlUnitModel';

interface SelectRegulatorOasisModalProps extends InfoModalProps {
  selectRegulator: (cu: IControlUnitDeviceOasis) => void;
  controlUnitList: IControlUnitDeviceOasis[];
}
function SelectRegulatorOasisModal(props: SelectRegulatorOasisModalProps) {
  const { selectRegulator, controlUnitList, ...rest } = props;
  const polyglot = getPolyglot();
  return (
    <InfoModal {...props}>
      <List
        aria-labelledby="nested-list-subheader"
        subheader={
          <FormLabel aria-label="nested-list-subheader">
            {polyglot.t('device.controlUnit.controller')}
          </FormLabel>
        }
      >
        {controlUnitList.map((cuDevice) => (
          <RegulatorItemButton
            cuDevice={cuDevice}
            key={cuDevice?.id}
            onClick={() => selectRegulator(cuDevice)}
          />
        ))}
      </List>
    </InfoModal>
  );
}

export default SelectRegulatorOasisModal;

const RegulatorItemButton = (props: {
  cuDevice?: IControlUnitDeviceOasis;
  onClick: ListItemButtonProps['onClick'];
}) => {
  const { cuDevice, onClick } = props;
  const polyglot = getPolyglot();
  return (
    <ListItemButton key={cuDevice?.id} onClick={onClick}>
      <ListItemIcon>
        {ControlUnitModel.getControlUnitIconDefault()}
      </ListItemIcon>
      <ListItemText
        primary={cuDevice?.controlUnit?.name}
        secondary={`${polyglot.t('incident_manager.titles_columns.device')}: ${
          cuDevice?.device?.deviceName
        }, ${polyglot.t('incident_manager.titles_columns.cu')}: ${
          cuDevice?.controlUnit?.id
        }`}
      />
    </ListItemButton>
  );
};
