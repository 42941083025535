import { Box, Button, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { ComposedParametersValue } from '../../../../../../redux/CUParameters/selectors';
import { ParameterProps } from './Parameter';
import { ParameterEditControl } from './ParameterEditControl/ParameterEditControl';
import { getParameterTitle } from '../../../../../../redux/CUParameters/api/util/parameterUtil';
import {
  DialogModal,
  DialogModalProps,
  SingleData,
} from '../../../../../../theme/components';
import { getPolyglot } from '../../../../../../i18n';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import { DEFAULT_DECIMALS } from '../../../../../../redux/controlUnit/api/util/constants';
import {
  TemperatureUnit,
  celsiusToFahrenheit,
  fahrenheitToCelsius,
  getTemperature,
} from '../../../../../../customized/fresh-watch/theme/utils/temperatureUtils';
import {
  LocalStorageKeys,
  getLocalStorageValue,
} from '../../../../../../util/SharedUtils';

export interface ParameterEditModalProps
  extends ParameterProps,
    Omit<DialogModalProps, 'onSave'> {
  onSave: (parameter: ComposedParametersValue, newValue: string) => void;
}
export function ParameterEditModal(props: ParameterEditModalProps) {
  const { parameter, onSave } = props;
  const showErrorMessage = useShowErrorMessage();
  const title = parameter.titleTranslation;
  const preferredTemperatureUnit = (getLocalStorageValue(
    LocalStorageKeys.temperatureUnit
  ) ||
    parameter.unitString ||
    '°C') as TemperatureUnit;

  const resetValue = (value: number) => {
    /* Transforms back information to °C */
    if (preferredTemperatureUnit === '°F') value = fahrenheitToCelsius(value);

    return Number(value);
  };

  /* Sets the temperature depending on users chose */
  const incomingFixedValue = getTemperature(
    Number(parameter.value ?? parameter.min.toFixed()),
    preferredTemperatureUnit
  ).toFixed(parameter.decimals ?? DEFAULT_DECIMALS);

  const [temporalValue, setTemporalValue] = useState(incomingFixedValue);
  const [errorForm, setErrorForm] = useState<string | null | undefined>(
    undefined
  );
  useEffect(() => {
    if (props.open) setTemporalValue(incomingFixedValue);
  }, [props.open]);
  const onClickSave = () => {
    /* Saves the value in °C */
    const result = resetValue(parseFloat(temporalValue));
    if (errorForm) return showErrorMessage(new Error(errorForm));
    else onSave(parameter, result.toString());
  };
  const onChange = (value: string, error?: string) => {
    setTemporalValue(value);
    setErrorForm(error);
  };
  return (
    <DialogModal {...props} onConfirm={onClickSave}>
      <Stack spacing={1} minWidth={300} px={2}>
        <Typography variant="h4">{title}</Typography>
        <Typography>{parameter.description}</Typography>
        <Box m={1}>
          <ParameterEditControl
            {...props}
            value={temporalValue}
            onChangeValue={onChange}
          />
        </Box>
      </Stack>
    </DialogModal>
  );
}
