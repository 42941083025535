import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, Autocomplete, Box } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export interface MultiSelectAutocompleteProps<T> {
  options: T[];
  label: string;
  placeholder: string;
  clearSelectedOptions: boolean;
  onSelectionChange: (selectedOptions: T[]) => void;
  equalFn?: (option: T, value: T) => boolean;
  displayResultFn?: (option: T) => string;
  labelFn?: (option: T) => string;
  filterOptionsFn?: (options: T[], state: any) => T[];
}
function MultiSelectAutocomplete<T>({
  options,
  label,
  placeholder,
  clearSelectedOptions = false,
  equalFn = (option: T, value: T) => option === value,
  displayResultFn = (option: T) => String(option),
  onSelectionChange,
  labelFn = (option: T) => String(option),
  filterOptionsFn,
}: MultiSelectAutocompleteProps<T>) {
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);

  useEffect(() => {
    setSelectedOptions([]);
    onSelectionChange([]);
  }, [clearSelectedOptions]);

  useEffect(() => {
    if (!options.length) {
      setSelectedOptions([]);
    }
  });

  return (
    <Autocomplete
      multiple
      options={options}
      isOptionEqualToValue={equalFn}
      disableCloseOnSelect
      getOptionLabel={labelFn}
      renderOption={(props, option, { selected }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
          />
          {labelFn(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            placeholder: selectedOptions.length > 0 ? '' : placeholder,
            startAdornment: (
              <Box
                component="span"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '70%',
                  marginRight: '10px',
                }}
              >
                {selectedOptions.map(displayResultFn).join(', ')}
              </Box>
            ),
          }}
        />
      )}
      value={selectedOptions}
      onChange={(_event, newValue) => {
        setSelectedOptions(newValue);
        onSelectionChange([...newValue]);
      }}
      renderTags={() => null}
      filterOptions={(options, state) => {
        if (filterOptionsFn) {
          return filterOptionsFn(options, state);
        }
        return options.filter((option) =>
          labelFn(option).toLowerCase().includes(state.inputValue.toLowerCase())
        );
      }}
    />
  );
}

export default MultiSelectAutocomplete;
