import React from 'react';
import { useShowErrorMessage } from '../../../../../handlingErrors';
import { getPolyglot } from '../../../../../i18n';
import { useCurrentStateByControlUnitId } from '../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { Base64 } from '../../../../../util/Base64';
import {
  alpha,
  Box,
  Button,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { EditPenIcon, PowerIcon } from '../../icons';
import { styled, useMediaQuery } from '@mui/system';
import { MerchandiseShelf } from '../MerchandiseCard';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../../redux/controlUnit/api/util/sensor/getValueStrFromSensor';
import SetPointEdit from '../../../../../components/DeviceManager/ControlUnit/ControlUnitPage/ControlUnitDetailPage/SetPoints/SetPointEdit';
import useStateBoolean from '../../../../../util/hooks/useStateBoolean';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import { getMainSetPointFromMerchandiserAndCUId } from '../../../redux/composableDevice/selectors/cu/SetPointSelectors';
import { useAppSelector } from '../../../../../redux/store.model';
import { DEFAULT_EVENT_ON } from '../../../../../redux/controlUnit/api/util/constants';
import CUActionsAPI from '../../../../../redux/CUActions/api/CUActionsAPI';
import LoadingButton from '@mui/lab/LoadingButton';

const ShelfDetail = (props: {
  controlUnitId: string;
  commanderId: string;
  merchandiser: Merchandiser;
  variant: 'heated' | 'refrigerated';
  displayZone?: boolean;
  itemNumber?: number;
}) => {
  const {
    merchandiser,
    controlUnitId,
    commanderId,
    variant,
    displayZone = true,
    itemNumber,
  } = props;

  const showErrorMessage = useShowErrorMessage();
  const polyglot = getPolyglot();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mainSensor, loading, isOn, cloudConnectivity, hasAlarms, actions } =
    useCurrentStateByControlUnitId(
      Base64.encode(commanderId),
      controlUnitId,
      showErrorMessage
    );
  const setPoint = useAppSelector((state) =>
    getMainSetPointFromMerchandiserAndCUId(state, {
      controlUnitId,
      deviceId: Base64.encode(commanderId),
      groupId: merchandiser.id,
      merchandiserId: merchandiser.id,
    })
  );

  const onAction = actions.find(
    (action) => action.metadata.id === DEFAULT_EVENT_ON
  );

  const loadingComponent = <Skeleton variant="text" width={100} />;
  const [showEditSetPoint, openSetPoint, closeSetPoint] =
    useStateBoolean(false);

  const value = loading
    ? loadingComponent
    : getValueStrFromSensor(mainSensor, isOn, cloudConnectivity, polyglot);
  const unit = loading ? null : getUnitStrFromSensor(mainSensor, isOn);

  const hasError = hasAlarms > 0;

  const [triggerOnAction, { isLoading: isLoadingOnAction }] =
    CUActionsAPI.useTriggerActionMutation({
      fixedCacheKey: `triggerAction-${commanderId}-${controlUnitId}-${onAction?.metadata.id}`,
    });
  const onClickOnOff = () => {
    triggerOnAction({
      deviceId: commanderId,
      controlUnitId: parseInt(controlUnitId),
      actionId: onAction?.metadata.id ?? DEFAULT_EVENT_ON,
      value: !onAction?.value,
    })
      .unwrap()
      .catch((error) => {
        error.message.startsWith('POLLING_')
          ? showErrorMessage('device.controlUnit.action.error.pooling', {
              variant: 'warning',
            })
          : showErrorMessage(error);
      });
  };

  return (
    <StyledCard
      error={hasError}
      isOn={isOn}
      height={`${
        variant === 'heated'
          ? 'auto'
          : variant === 'refrigerated' && displayZone
          ? '100%'
          : '40vh'
      }!important`}
      sx={{
        boxShadow: 2,
      }}
    >
      {displayZone && (
        <StyledZone isOn={isOn} error={hasError}>
          {polyglot.t('freshwatch.dashboard.zone')} {itemNumber ?? ''}
        </StyledZone>
      )}
      <Box
        sx={{
          padding: '1rem',
          width: '100%',
          display: 'flex',
          gap: '1rem',
          flexDirection: variant === 'heated' ? 'row-reverse' : 'column',
        }}
      >
        <Box
          sx={{ alignSelf: variant === 'heated' ? 'center' : 'flex-end' }}
          display={'flex'}
          alignItems={'center'}
          flexDirection={
            isMobile && variant !== 'refrigerated' ? 'column' : 'row'
          }
          gap="1rem"
        >
          {setPoint ? (
            <StyledButton
              onClick={() => !showEditSetPoint && openSetPoint()}
              startIcon={<EditPenIcon />}
              variant="outlined"
              color={hasError ? 'error' : 'primary'}
            >
              {polyglot.t('freshwatch.dashboard.setpoint')}
              <SetPointEdit
                parameter={setPoint.parameter}
                open={showEditSetPoint}
                onClose={closeSetPoint}
                encodedDeviceId={Base64.encode(commanderId)}
                controlUnitId={controlUnitId}
              ></SetPointEdit>
            </StyledButton>
          ) : null}
          <StyledButton
            startIcon={<PowerIcon />}
            loading={isLoadingOnAction}
            variant="contained"
            color={hasError ? 'error' : 'primary'}
            onClick={onClickOnOff}
          >
            {!isOn
              ? polyglot.t('freshwatch.dashboard.off')
              : polyglot.t('freshwatch.dashboard.on')}
          </StyledButton>
        </Box>
        <Box
          textAlign={'center'}
          flexDirection={isMobile ? 'column' : 'row'}
          sx={{
            display: 'flex',
            margin: 'auto',
            gap: '0.5rem',
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          <Box>
            <StyledDescription error={hasError} isOn={isOn}>
              {polyglot.t('freshwatch.dashboard.zone_temperature')}
            </StyledDescription>
            <StyledTitle error={hasError} isOn={isOn}>
              <MerchandiseShelf
                value={value}
                unit={unit}
                variant={variant}
                error={hasError}
                isOn={isOn}
              />
            </StyledTitle>
          </Box>
          <Box>
            <StyledDescription error={hasError} isOn={isOn}>
              {polyglot.t('freshwatch.dashboard.internal_product_temperature')}
            </StyledDescription>
            <StyledTitle error={hasError} isOn={isOn}>
              <MerchandiseShelf
                value={'TBD'}
                unit={unit}
                variant={variant}
                error={hasError}
                isOn={isOn}
              />
            </StyledTitle>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

const StyledCard = styled(Box)<{ error?: boolean; isOn?: boolean }>(
  ({ error, isOn, theme }) => `
      display: flex;
      border-radius: 1rem;
      background: ${theme.palette.background.paper};
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      ${error ? `background: ${theme.palette.error.light};` : ''}
      ${!isOn ? `background: ${alpha(theme.palette.gray.light, 0.7)};` : null}
  `
);

const StyledZone = styled(Box)<{ error?: boolean; isOn?: boolean }>(
  ({ error, isOn, theme }) => `
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: 1rem;
      border-radius: 1rem 0 0 1rem;
      display: flex;
      align-items: center;
      text-align: center;
      ${
        error
          ? `
          background: ${theme.palette.error.main};
          color: ${theme.palette.primary.contrastText};
          `
          : ''
      }
        ${
          !isOn
            ? `
          background: ${theme.palette.gray.dark};
          `
            : ''
        }
    `
);
const StyledTitle = styled(Typography)<{ error?: boolean; isOn?: boolean }>(
  ({ error, isOn, theme }) => `
      color: ${error ? theme.palette.error.main : theme.palette.common.black};
      font-size: 2rem;
  
      ${!isOn ? `color: ${theme.palette.gray.dark}` : ''}
  `
);
const StyledDescription = styled(Typography)<{
  error?: boolean;
  isOn?: boolean;
}>(
  ({ error, isOn, theme }) => `
      color: ${error ? theme.palette.error.main : theme.palette.gray.main};
      font-size: 0.8rem;
      ${!isOn ? `color: ${theme.palette.gray.dark}` : ''}
  
  `
);
const StyledButton = styled(LoadingButton)`
  border-radius: 1.5rem;
`;
export default ShelfDetail;
