import React from 'react';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import {
  Box,
  darken,
  lighten,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IncidentAPIResponseMVP } from '../../../../../../redux/incident/api/Incident.model';
import { getPolyglot } from '../../../../../../i18n';
import { AlarmIcon } from '../../../icons';
import InfoCard from '../../InfoCard/InfoCard';

const StoreOverviewCard = (props: {
  city: Group;
  merchandisers: Merchandiser[];
  alarms: IncidentAPIResponseMVP[];
}) => {
  const { city, merchandisers, alarms } = props;
  const polyglot = getPolyglot();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const error = alarms.length > 0;

  return (
    <InfoCard
      infoTitle={city.name}
      subtitle={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            background: error
              ? theme.palette.error.light
              : theme.palette.neutral.main,
            padding: '0',
          }}
        >
          <Typography variant="h6" color="common.black">
            {polyglot.t('freshwatch.dashboard.total')}
          </Typography>
          {error ? (
            <Typography variant="h6" color="common.black">
              {polyglot.t('freshwatch.dashboard.alarms')}
            </Typography>
          ) : null}
        </Box>
      }
      error={error}
      sx={{
        width: '100%',
        maxWidth: isMobile ? '100%' : '300px',
        boxShadow: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
          justifyContent: 'space-evenly',
          background: error
            ? lighten(theme.palette.error.light, 0.7)
            : theme.palette.common.white,
        }}
      >
        <Typography fontSize={'2.5rem'} variant="h4">
          {merchandisers.length}
        </Typography>
        {error ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AlarmIcon height={'2rem'} color={theme.palette.error.main} />
            <Typography
              sx={{ padding: '0 0 0 0.2rem' }}
              fontSize={'2.5rem'}
              variant="h4"
            >
              {alarms.length}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </InfoCard>
  );
};
export default StoreOverviewCard;
