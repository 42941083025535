export const formatNumber = (value: number, decimals: number) => {
  return Number(value.toFixed(decimals));
};

export const fahrenheitToCelsius = (value: number) => {
  return (value - 32) / 1.8;
};

export const celsiusToFahrenheit = (value: number) => {
  return value * 1.8 + 32;
};

export const getTemperature = (
  value: number,
  temperatureUnit: TemperatureUnit
) => {
  return temperatureUnit === '°F' ? celsiusToFahrenheit(value) : value;
};

export type TemperatureUnit = '°F' | '°C';
