import React from 'react';
import { ChartsFilters } from './components/ChartsFilters/ChartsFilters';
import { ComboCharts } from './components/ComboCharts/ComboCharts';
import { useMetrics } from '../../../../redux/monitoring/hooks/useMetrics';
import './MonitoringScreen.scss';
import { MonitoringScreenProps } from '../../../../redux/monitoring/api/monitoring-ui.model';
import { useDevice } from '../../../../redux/devices/hooks/useDevice';
import WidthRefResize from '../../../../util/renderProps/WidthRefResize';
import {
  Backdrop,
  Typography,
  CircularProgress,
  Box,
  Alert,
} from '@mui/material';

/**
 * https://www.commander-cloud.eu/api/frontend/device/sensordata?ids=[%ZjZmMzAwZjktZTBmNC00NWRjLWE3ZTUtNWI5MTU4NjljZDMz%22]&start=1623325610392&end=1623412010393
 * devicemanager/YWI2ZmI0YTMtOWVlYy00MWJlLTkzNGYtNzliMGIzYzZmOTM1/device/ZjZmMzAwZjktZTBmNC00NWRjLWE3ZTUtNWI5MTU4NjljZDMz/monitoring
 */

export function MonitoringScreen(props: MonitoringScreenProps) {
  const { params, polyglot, error } = props;
  const {
    deviceId,
    controlUnitId,
    merchandiserId,
    groupId = merchandiserId,
  } = params;

  /** Init Groups */
  useDevice(deviceId ?? '');

  /** Init Monitoring */
  const {
    timeControls: { timeFrame, setTimeFrame },
    timeControls,
    analogControls,
    digitalControls,
    loading,
    metrics,
  } = useMetrics();

  return (
    <WidthRefResize
      render={(width) => (
        <div id="monitoring-charts">
          {
            <div>
              <ChartsFilters
                onChangeDatePicker={setTimeFrame}
                polyglot={polyglot}
                groupId={groupId}
                deviceId={deviceId}
                controlUnitId={controlUnitId}
                analogControls={analogControls}
                digitalControls={digitalControls}
                timeFrame={timeControls.timeFrame}
              />
              {loading ? (
                <Backdrop
                  sx={{
                    backgroundColor: '#ffffffA2',
                    zIndex: 1001,
                  }}
                  open={loading}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <CircularProgress color="inherit" />
                    <Typography>{polyglot.t('general.loading')}</Typography>
                  </Box>
                </Backdrop>
              ) : error ? (
                <Alert severity="warning">{polyglot.t('error.unknown')}</Alert>
              ) : (
                <ComboCharts
                  {...props}
                  digitalChannels={metrics.digitalChannels}
                  sensordata={metrics.sensordata}
                  timeControls={timeControls}
                  timeFrame={timeFrame}
                  maxWidth={width - 32}
                />
              )}
            </div>
          }
        </div>
      )}
    />
  );
}
