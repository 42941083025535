import React from 'react';
import MerchandiseCard from '../../../../theme/components/MerchandiseCard';
import MerchandiseSection from '../../../../theme/components/MerchandiseCard/MerchandiseSection';
import MerchandiseShelfConnected from './MerchandiseShelfConnected';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import { Skeleton } from '@mui/material';
import { getPolyglot } from '../../../../../../i18n';
interface MerchandiseCardConnectedProps {
  merchandiser: Merchandiser;
}
function MerchandiseCardConnected(props: MerchandiseCardConnectedProps) {
  const { merchandiser } = props;

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) =>
      getAlarmActiveBySelector(state, {
        groupId: merchandiser.id,
      })
    );
  const polyglot = getPolyglot();

  // TODO: Implement the logic for alarmsHeated and alarmsRefrigerated
  const alarmsHeated = alarmsActive.length > 0;
  const alarmsRefrigerated = alarmsActive.length > 0;

  const subtitle = isLoadingAlarmsActive ? (
    <Skeleton variant="text" width={100} />
  ) : (
    merchandiser.composableBluePrint?.taqs?.family
  );

  return (
    <MerchandiseCard
      merchTitle={merchandiser.name}
      subtitle={subtitle}
      variant={
        merchandiser.refrigeratedShelfs.length &&
        merchandiser.heatedShelfs.length
          ? 'heated'
          : 'refrigerated'
      }
      error={alarmsActive.length > 0}
      href={`/merchandiser/${merchandiser.id}`}
    >
      {merchandiser.refrigeratedShelfs.length ? (
        <MerchandiseSection
          error={alarmsRefrigerated}
          name={polyglot.t('freshwatch.dashboard.refrigerated')}
          variant="refrigerated"
        >
          {merchandiser.refrigeratedShelfs?.map((shelf, i) => (
            <MerchandiseShelfConnected
              commanderId={shelf.commanderId}
              controlUnitId={shelf.controlUnitId}
              key={i}
              variant="refrigerated"
            />
          ))}
        </MerchandiseSection>
      ) : null}
      {merchandiser.heatedShelfs.length ? (
        <MerchandiseSection
          error={alarmsHeated}
          name={polyglot.t('freshwatch.dashboard.heated')}
          variant="heated"
        >
          {merchandiser.heatedShelfs?.map((shelf, i) => (
            <MerchandiseShelfConnected
              commanderId={shelf.commanderId}
              controlUnitId={shelf.controlUnitId}
              key={i}
              variant="heated"
            />
          ))}
        </MerchandiseSection>
      ) : null}
    </MerchandiseCard>
  );
}

export default MerchandiseCardConnected;
