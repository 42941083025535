import React, { useMemo, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Paper,
  TextField,
} from '@mui/material';
import { useShowErrorMessage } from '../../../../handlingErrors';
import SortUtil from '../../../../util/SortUtil';
import { fetchDeviceByCloudConnectId } from '../../../../redux/devices/actions/thunks';
import { putGroupAddDevices } from '../../../../redux/groups/actions/putGroupAddDevices';
import { getPolyglot } from '../../../../i18n';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../redux/store.model';
import {
  Device,
  DeviceDataAPIResponse,
} from '../../../../redux/devices/api/device.model';
import {
  getAllAvailableDevicesToAddByGroupIdSelector,
  getGroupByPropGroupIdSelector,
} from '../../../../redux/groups/selectors';
import { STModal } from '../../../commons/Modal';
import { getFeatureToggle } from '../../../../featureToggle';
import StoerkIdInsufficientDevicesQuotaModal from '../../../StoerkId/MyStoerkId/StoerkIdInsufficientDevicesQuotaModal';
import { getMyWorkspaceSelector } from '../../../../redux/stoerkID/selectors/StoerkId.selectors';
import { stoerkIdInsufficientDevicesQuotaSelector } from '../../../../redux/stoerkID/selectors/StoerkIdRestrictions.selectors';
import { useFormik } from 'formik';
import { useShowMessage } from '../../../../util/hooks';
import ButtonLoading from '../../../commons/ButtonLoading/ButtonLoading';
import { ErrorText } from '../../../../theme/components/Forms';

interface GroupDeviceAssignProps {
  showGroupDeviceAssign: boolean;
  groupId?: string;
  closeGroupDeviceAssign: () => void;
}

interface initialValuesProps {
  deviceIds: string[];
}

export default function GroupDeviceAssign({
  showGroupDeviceAssign,
  groupId,
  closeGroupDeviceAssign,
}: GroupDeviceAssignProps) {
  const polyglot = getPolyglot();
  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();
  const featureToggle = useAppSelector(getFeatureToggle);
  const dispatch = useAppDispatch();
  const availableDevicesToAdd = useAppSelector((state: RootState) =>
    getAllAvailableDevicesToAddByGroupIdSelector(state, { groupId })
  );
  const showStoerkIdInsufficientDevicesQuota = useAppSelector(
    (state: RootState) => stoerkIdInsufficientDevicesQuotaSelector(state)
  );

  const workspace = useAppSelector((state: RootState) =>
    getMyWorkspaceSelector(state)
  );
  const group = useAppSelector((state: RootState) =>
    getGroupByPropGroupIdSelector(state, { groupId })
  );

  const [showDeviceQuota, setShowDeviceQuota] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [devicesId, setDevicesId] = useState<string[]>([]);

  const options = useMemo(() => {
    let devicesOptions: (Device | DeviceDataAPIResponse)[] =
      availableDevicesToAdd;

    devicesOptions = SortUtil.multisort(devicesOptions, ['name'], ['ASC']);
    return devicesOptions.map((device) => ({
      key: device.uuid,
      value: device.uuid,
      text: device.name,
    }));
  }, [availableDevicesToAdd]);

  const initialValues: initialValuesProps = {
    deviceIds: [],
  };
  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      const errors: Record<string, string> = {};
      if (values.deviceIds.length === 0) {
        errors.deviceIds = polyglot.t('error.formular.empty_list');
      }

      const optionTexts = new Set(options.flatMap((item) => item.text));
      const newValues = values.deviceIds.filter(
        (valueOption) => !optionTexts.has(valueOption)
      );
      const uuids = [];
      for (const ccId of newValues) {
        try {
          const device = await getDeviceByCloudId(ccId);

          if (device) {
            if (group?.devices.find((uuid) => uuid === device?.uuid)) {
              const message = polyglot
                .t('group.devices.device_already_associated_to_group')
                .replace('#text', device.name || '');

              showError(message);
            } else {
              uuids.push(device.uuid);
            }
          }
          setDevicesId([...Array.from(new Set(uuids))]);
        } catch (error: any) {
          showError(
            `${polyglot.t('device.connect_id')}: ${ccId}: ${error.message}`
          );
        } finally {
          setShowLoading(false);
        }
      }

      return errors;
    },
    onSubmit: async (values, helpers) => {
      const optionTexts = new Set(options.flatMap((item) => item.text));

      const valuesFromTheOptions = values.deviceIds.filter((valueOption) =>
        optionTexts.has(valueOption)
      );

      const uuids = valuesFromTheOptions.reduce((acc: string[], value) => {
        const matchingOption = options.find((option) => option.text === value);
        if (matchingOption) {
          acc.push(matchingOption.value);
        }
        return acc;
      }, []);
      const devicesToAdd = uuids.concat(devicesId);

      try {
        if (devicesToAdd.length === 0) {
          throw polyglot.t('error.unknown_ccid');
        }

        await dispatch(
          putGroupAddDevices(groupId || '', [...new Set(devicesToAdd)])
        );

        const message =
          devicesId.length > 1
            ? polyglot.t('group.devices.assign_several_successful_message')
            : polyglot.t('group.devices.assign_successful_message');
        showSuccess(message);
        closeModal();
      } catch (err) {
        showError(polyglot.t('group.devices.assign_fail_message'));
      }
    },
  });

  const handleOnChange = (event: unknown, values: string[]) => {
    formik.setValues({
      deviceIds: [...values],
    });
  };

  async function getDeviceByCloudId(
    cloudConnectID: string
  ): Promise<DeviceDataAPIResponse | undefined> {
    /** review quota */
    if (showStoerkIdInsufficientDevicesQuota) {
      setShowDeviceQuota(true);
      return undefined;
    }

    setShowLoading(true);
    if (cloudConnectID === null || !cloudConnectID.trim()) {
      throw new Error(polyglot.t('error.empty_ccid'));
    }
    const devicePreview = await fetchDeviceByCloudConnectId(
      cloudConnectID.trim()
    );
    setShowLoading(false);
    return devicePreview;
  }

  const closeModal = () => {
    setDevicesId([]);
    formik.setFieldValue('deviceIds', []);
    closeGroupDeviceAssign();
  };

  if (
    showDeviceQuota &&
    featureToggle.stoerkId &&
    featureToggle.stoerkIdRestrictions
  )
    return (
      <StoerkIdInsufficientDevicesQuotaModal
        open={showGroupDeviceAssign}
        onClose={closeGroupDeviceAssign}
        workspace={workspace}
      />
    );
  return (
    <div>
      <STModal
        open={showGroupDeviceAssign}
        sx={{
          '&& .MuiDialogContent-root,.MuiPaper-root': {
            overflowY: 'initial',
          },
        }}
        id="modalAddDevice"
        title={polyglot.t('group.devices.assign_dialog_title')}
        onClose={closeModal}
        buttonActions={
          <>
            <Button type="button" variant="text" onClick={closeModal}>
              {polyglot.t('group.cancel_button_title')}
            </Button>
            <ButtonLoading
              type="submit"
              variant="contained"
              onClick={formik.submitForm}
              loading={formik.isSubmitting}
            >
              {polyglot.t('group.devices.assign_device_button_title')}
            </ButtonLoading>
          </>
        }
      >
        <form className="formular-material-design">
          {(showLoading || formik.isSubmitting) && <CircularProgress />}
          <div>
            <Autocomplete
              multiple
              id="tags-filled"
              options={options.map((option) => option.text ?? '')}
              defaultValue={[]}
              freeSolo
              value={formik.values.deviceIds}
              onChange={(event, newValue) =>
                handleOnChange(event, newValue as string[])
              }
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      label={option}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={polyglot.t('group.devices.device')}
                  placeholder="Select a commander or enter a cloud connect Id"
                />
              )}
            />
            {formik.errors.deviceIds && (
              <ErrorText>{formik.errors.deviceIds}</ErrorText>
            )}
            <Alert severity={'info'} sx={{ mt: 1 }}>
              {polyglot.t(
                'group.devices.select_device_or_insert_cloud_connect_id'
              )}
            </Alert>
          </div>
          <Paper id="placeHolderDropdowOpen" />
        </form>
      </STModal>
    </div>
  );
}
