import { createSelector } from '@reduxjs/toolkit';
import { Group } from '../api/group.model';
import { getRightUserUtilSelector } from '../../rights/selectors';
import { RootState } from '../../store.model';
import { getAllGroups } from '../../devices/selectors';

const getGroupIdFromProps = (_: any, props: { group?: Group }) => props.group;

export const groupButtonsRightsSelector = createSelector(
  [
    getGroupIdFromProps,
    getRightUserUtilSelector,
    (state: RootState) => state.featureToggle,
  ],
  (group, rightsUserUtil, featureToggle) => {
    const groupId = group?.id ?? '';
    const showButtonReadIncidents = !!rightsUserUtil.hasRightsToReadIncident(
      null,
      groupId
    );
    const showButtonUpdateGroup =
      !!rightsUserUtil.hasRightsToUpdateGroup(groupId);
    const showButtonDeleteGroup =
      !!rightsUserUtil.hasRightsToDeleteGroup(groupId);
    const showButtonRecipeGroup = !!(
      featureToggle.recipeManager &&
      rightsUserUtil.hasRightsToReadRecipe(null, groupId)
    );
    const showButtonCameraGroups = !!featureToggle.camera;
    const contacts = group?.attributes?.contacts ?? [];
    const showButtonContacts = contacts.length > 0;

    return {
      showButtonReadIncidents,
      showButtonUpdateGroup,
      showButtonDeleteGroup,
      showButtonRecipeGroup,
      showButtonCameraGroups,
      showButtonContacts,
    };
  }
);

export const getDashboardRightsSelector = createSelector(
  [getRightUserUtilSelector, getAllGroups],
  (rightsUserUtil, groups) => {
    const canViewOperatorDashboard = groups.some((group) =>
      rightsUserUtil.hasRightsToViewDashboardAsOperator(null, group.id)
    );

    const canViewRegionalManagerDashboard = groups.some((group) =>
      rightsUserUtil.hasRightsToViewDashboardAsManager(null, group.id)
    );

    return {
      canViewOperatorDashboard,
      canViewRegionalManagerDashboard,
    };
  }
);
