import React from 'react';
import { ContentPage } from './MerchandiserLayout';
import { ConnectedMonitoringScreen } from '../../../../components/DeviceManager/Device/Monitoring/ConnectedMonitoringScreen';

function MerchandiserMonitoring() {
  return (
    <ContentPage variant="elevation">
      <ConnectedMonitoringScreen />
    </ContentPage>
  );
}

export default MerchandiserMonitoring;
