import React, { useState } from 'react';

import IncidentList from './IncidentList';
import { CircularProgress, Container, Typography } from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { getMyWorkspaceSelector } from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import { useAppSelector } from '../../../redux/store.model';
import { useParams } from 'react-router-dom';
import { useDevice } from '../../../redux/devices/hooks/useDevice';
import { useGroupById } from '../../../redux/groups/hooks/useGroupById';
import { useControlUnitById } from '../../../redux/controlUnit/hooks/useControlUnitById';
import IncidentFilters, { IncidentFiltersProps } from './IncidentFilters';

function IncidentListPage() {
  const polyglot = getPolyglot();
  const workspace = useAppSelector(getMyWorkspaceSelector);
  const params = useParams<{
    // Encoded group id
    groupId?: string;
    // Merchandise id == group id
    merchandiseId?: string;
    // Encoded device id
    deviceId?: string;
    controlUnitId?: string;
  }>();

  const { controlUnitId, deviceId, merchandiseId } = params;
  let { groupId } = params;
  groupId = groupId ?? merchandiseId;

  const [group] = useGroupById(groupId ?? '');
  const [device] = useDevice(deviceId ?? '');

  const [controlUnit] = useControlUnitById(
    deviceId ?? '',
    controlUnitId ?? '',
    () => {}
  );

  const [listFilterCategory, setListFilterCategory] =
    useState<IncidentFiltersProps['valueTab']>('ACTIVE');

  const onChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setListFilterCategory(newValue as IncidentFiltersProps['valueTab']);
  };

  const getTitle = () => {
    //render title for control unit
    if (device && controlUnit?.name) {
      return `${controlUnit?.name} ${polyglot.t('incident.incidents_table')}`;
    }
    //render title for device
    if (device?.name && !controlUnit) {
      return `${device?.name} ${polyglot.t('incident.incidents_table')}`;
    }
    //render title for group
    if (group && group?.name && !device && !controlUnit) {
      return `${group?.name} ${polyglot.t('incident.incidents_table')}`;
    }

    //default
    return polyglot.t('incident.all_alarms');
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        height: '100%',
        py: 1,
      }}
    >
      <Typography variant="h1">{getTitle()}</Typography>
      <IncidentFilters
        valueTab={listFilterCategory}
        onChangeTab={onChangeTab}
      />
      {!workspace?.id ? (
        <CircularProgress />
      ) : (
        <IncidentList
          workspaceId={workspace?.id ?? ''}
          filterByActive={listFilterCategory === 'ACTIVE'}
          cuId={controlUnitId}
          deviceId={deviceId}
          groupId={groupId}
        />
      )}
    </Container>
  );
}

export default IncidentListPage;
