import Polyglot from 'node-polyglot';
import { SensordataNewestMixed } from '../../../../../redux/monitoring/api/monitoring.model';
import { DEFAULT_DECIMALS, DEFAULT_VALUE_SENSOR } from '../constants';
import {
  LocalStorageKeys,
  getLocalStorageValue,
} from '../../../../../util/SharedUtils';

/**
 * Sensor value to show in web, extracted from sensorValueToShowInWeb
 * This function returns the value to be shown in the web frontend by a sensor
 * If the field value = false then the DEFAULT_VALUE_SENSOR will be shown
 * If the value is not undefined and not null and is numeric then the value is shown
 * TODO: If the known mapped error as systemErrorId show it
 * In other case the DEFAULT_VALUE_SENSOR is shown.
 * @param object controlUnit
 * @param object sensor
 * @return float/string value
 */
export function getValueStrFromSensor(
  sensor: SensordataNewestMixed | undefined,
  isOn: boolean | undefined,
  isConnected: boolean | undefined,
  polyglot: Polyglot
) {
  if (!isConnected) return polyglot.t('device.controlUnit.offline');
  // value is not available yet
  if (isOn === undefined) return DEFAULT_VALUE_SENSOR;

  // to show off value
  if (isOn === false) return polyglot.t('general.off');

  const decimals = sensor?.metadata?.decimals ?? DEFAULT_DECIMALS;
  // sensor in invalid state
  if (sensor?.state !== 0) return DEFAULT_VALUE_SENSOR;
  // value of sensor unknown
  if (typeof sensor?.value != 'number') return DEFAULT_VALUE_SENSOR;

  const valueSensor = getValueWithPreferredUnit(
    sensor.value,
    sensor?.metadata?.unitString
  );

  const value = valueSensor.toFixed(decimals);
  return value;
}

export function getUnitStrFromSensor(
  sensor: SensordataNewestMixed | undefined,
  isOn: boolean | undefined
) {
  // to show off value
  if (isOn === false) return '';

  const unit =
    getLocalStorageValue(LocalStorageKeys.temperatureUnit) ||
    sensor?.metadata?.unitString;

  return unit ?? '';
}

export function getValueWithPreferredUnit(
  value: number,
  defaultUnit: string | undefined
) {
  /* By default the sensor metadata is in °C */
  const unit =
    getLocalStorageValue(LocalStorageKeys.temperatureUnit) || defaultUnit;

  if (unit === '°F') {
    /* Change the value from Celcius to Farenheit  */
    value = value * 1.8 + 32;
  }
  return value;
}
