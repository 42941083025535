import { createSelector } from '@reduxjs/toolkit';
import { Device } from '../api/device.model';
import DeviceModel from '../api/DeviceModel';
import { RootState } from '../../store.model';
import { RouterComponentProps } from '../../../util/route-dom';
import { uniq } from 'lodash';
import GroupModel from '../../groups/api/GroupModel';
import { Group } from '../../groups/api/group.model';

export type RouteComponentPropsDevice = Pick<
  RouterComponentProps<{
    deviceId?: string;
  }>,
  'params'
>;

export const getGroups = (state: RootState) => state.groups.treeGroups;
export const getIndividualGroup = (state: RootState) =>
  state.groups.individualGroup;

export const getAllDevicesIdsOnGroupsSelector = createSelector(
  [getGroups, getIndividualGroup],
  GroupModel.getAllDevicesIdFromGroups
);

// ^ Functionality inherit
export const getDevicesData = (state: RootState) =>
  state.devices.dictionaryDevicesData;

export const getDevicesDataById = (
  state: RootState,
  props: { deviceId: string }
) => state.devices.dictionaryDevicesData[props.deviceId];

export const getDevicesDataLoadingById = (state: RootState) =>
  state.devices.loadingDevicesData;

export const getDevicesStatusById = (
  state: RootState,

  props: { deviceId: string }
) => state.devices.dictionaryDevicesStatus[props.deviceId];

export const getDevicesStatusLoadingById = (
  state: RootState,

  props: { deviceId: string }
) => state.devices.loadingDevicesStatus[props.deviceId];

export const getDevicesLabelById = (
  state: RootState,

  props: { deviceId: string }
) => state.devices.devicesLabels[props.deviceId];

export const getAssociatedDevices = (state: RootState) =>
  state.devices.associatedDevices;

export const isAssociatedDevicesLoading = (state: RootState) =>
  !!state.devices.loadingAssociatedDevices;

export const getDeviceIdFromQueryParam = (
  _: RootState,
  props: RouteComponentPropsDevice
) => props.params.deviceId;

export const getDeviceIdFromProps = (
  _: RootState,
  props: { deviceId: string }
) => props.deviceId;

export const queryPramsToProps = (
  _: RootState,
  props: RouteComponentPropsDevice
) => props.params;

const model = new DeviceModel();

export const getDevicesFromProps = (
  state: RootState,
  props: { deviceIds: string[] }
) => props.deviceIds.map((deviceId) => getDeviceFromProps(state, { deviceId }));

export const getDeviceFromProps = createSelector(
  [
    getDeviceIdFromProps,
    getDevicesDataById,
    getDevicesDataLoadingById,
    getDevicesStatusById,
    getDevicesStatusLoadingById,
    getDevicesLabelById,
  ],
  model.parseDevice
);

export const getDevicesByIds = (
  state: RootState,
  props: { devicesIds: string[] }
): Device[] =>
  props.devicesIds.map((deviceId) => getDeviceFromProps(state, { deviceId }));

export const getDevices = (state: RootState) =>
  getDevicesByIds(state, {
    devicesIds: getAllDevicesIds(state),
  });

export const getNumDevices = (state: RootState) =>
  Object.keys(state.devices.dictionaryDevicesData).length;

export const getDeviceSelected = createSelector(
  [(state) => state, getDeviceIdFromQueryParam],
  (state, deviceId = '') => getDeviceFromProps(state, { deviceId })
);
export const getDeviceSelectedName = createSelector(
  [getDeviceSelected],
  (device) => device?.name || ''
);

export const isDeviceInAssociatedDevices = createSelector(
  [getDeviceIdFromProps, getAssociatedDevices],
  (deviceId, devicesIds) => devicesIds.includes(deviceId)
);

export const getDevicesAssociated = createSelector(
  [(state) => state, getAssociatedDevices],
  (state, devicesIds) =>
    devicesIds.map((deviceId) => getDeviceFromProps(state, { deviceId }))
);

export const getAllDevicesIds = createSelector(
  [getAllDevicesIdsOnGroupsSelector, getAssociatedDevices],
  (devicesIdsOnGroups, associatedDevices) =>
    uniq([...devicesIdsOnGroups, ...associatedDevices])
);

/**
 *  Get all groups and their children
 * @param state
 * @returns Group[] - a list of the groups and all their children
 */
export const getAllGroups = createSelector([getGroups], (groups) => {
  const allGroups: Group[] = [];
  getAllChildrenGroups(groups, allGroups);

  return allGroups;
});

// Creates a new array with all groups and adds their children recursively
export function getAllChildrenGroups(groups: Group[], list: Group[]): Group[] {
  groups.map((group) => {
    if (group.childGroups !== null) {
      getAllChildrenGroups(group.childGroups, list);
      list.push(group);
    }
  });
  return list;
}
