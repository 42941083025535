import React from 'react';
import IncidentListPage from '../../../../components/Incident/IncidentListPage/IncidentListPage';
import { ContentPage } from './MerchandiserLayout';

function MerchandiserIncidentListPage() {
  return (
    <ContentPage variant="elevation">
      <IncidentListPage />
    </ContentPage>
  );
}

export default MerchandiserIncidentListPage;
