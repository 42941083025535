import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPolyglot } from '../../../../i18n';
import { useAppSelector } from '../../../../redux/store.model';
import { getGroups } from '../../../../redux/devices/selectors';
import { getGroupByPropGroupIdSelector } from '../../../../redux/groups/selectors';

/** basing in the last item on the path */
export function getModuleSelected(pathname: string) {
  const list = pathname.split('/');
  const moduleSelected = list[list.length - 1] ?? '';
  return moduleSelected;
}

const dicTitles: Record<string, string> = {
  incidents: 'incident_manager.title',
  recipes: 'recipe_manager.title',
  history: 'device.monitoring.title',
  settings: 'group.tooltip.open_window_edit',
};
/**
 * Navigation path
 * Build the navigation path used in the breadcrumb
 * @return array sectionsNavigation
 */
export function useNavigationPathMerchandiser(): {
  content: React.ReactNode;
  onClick: () => void;
}[] {
  const params = useParams<{ merchandiserId: string }>();
  const { merchandiserId } = params;
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const location = useLocation();
  const groupId = merchandiserId;

  const groups = useAppSelector(getGroups);
  const group = useAppSelector((state) =>
    getGroupByPropGroupIdSelector(state, { groupId })
  );
  const moduleSelected = getModuleSelected(location.pathname);

  const sectionsNavigation = [
    {
      content: polyglot.t('nav.menu.dashboard'),
      onClick: () => navigate('/dashboard'),
    },
  ];

  /* build path */
  if (groupId && groups.length > 0 && group !== undefined) {
    sectionsNavigation.push({
      content: group.name,
      onClick: () => navigate(`/merchandiser/${merchandiserId}`),
    });
  }

  /** module selected */
  if (moduleSelected && Object.keys(dicTitles).includes(moduleSelected ?? '')) {
    sectionsNavigation.push({
      content: polyglot.t(dicTitles[moduleSelected] ?? ''),
      onClick: () => navigate(`/merchandiser/${groupId}/${moduleSelected}`),
    });
  }
  return sectionsNavigation;
}
