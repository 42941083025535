import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MerchandiseDetailPage from '../MerchandiserDetailPage/MerchandiserDetailPage';
import MerchandiserLayout from './MerchandiserLayout';
import MerchandiserIncidentListPage from './MerchandiserIncidentListPage';
import MerchandiserMonitoring from './MerchandiserMonitoring';
import MerchandiserSettings from './MerchandiserSettings';

export function MerchandiserRoutes() {
  return (
    <React.Fragment>
      <Routes>
        <Route path=":merchandiserId" element={<MerchandiserLayout />}>
          <Route path="history" element={<MerchandiserMonitoring />} />
          <Route path="settings" element={<MerchandiserSettings />} />
          <Route path="alarms" element={<MerchandiserIncidentListPage />} />
          <Route path="detail" element={<MerchandiseDetailPage />} />
          {/* redirect to detail */}
          <Route path="" element={<Navigate to="detail" replace={true} />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}

export default MerchandiserRoutes;
