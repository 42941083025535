import React from 'react';
import {
  MerchandiseShelfProps,
  MerchandiseShelf,
} from '../../../../theme/components/MerchandiseCard';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import { Base64 } from '../../../../../../util/Base64';
import { Skeleton, Typography } from '@mui/material';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import {
  getUnitStrFromSensor,
  getValueStrFromSensor,
} from '../../../../../../redux/controlUnit/api/util/sensor/getValueStrFromSensor';
import { getPolyglot } from '../../../../../../i18n';
interface MerchandiseShelfConnectedProps {
  controlUnitId: string;
  commanderId: string;
  variant: MerchandiseShelfProps['variant'];
  type?: 'card' | 'text';
}
function MerchandiseShelfConnected(props: MerchandiseShelfConnectedProps) {
  const { controlUnitId, commanderId, variant, type } = props;
  const showErrorMessage = useShowErrorMessage();
  const polyglot = getPolyglot();
  const {
    mainSensor,
    loading: loadingCUState,
    controlUnit,
    isOn,
    cloudConnectivity,
  } = useCurrentStateByControlUnitId(
    Base64.encode(commanderId),
    controlUnitId,
    showErrorMessage
  );

  const displayType = type ?? 'card';

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) =>
      getAlarmActiveBySelector(state, {
        controlUnitId,
        deviceId: Base64.encode(commanderId),
      })
    );

  const loading = loadingCUState || isLoadingAlarmsActive;
  const loadingComponent =
    displayType === 'card' ? (
      <Skeleton variant="text" width={100} />
    ) : (
      <Skeleton
        variant="rectangular"
        width={20}
        height={15}
        sx={{ display: 'inline-flex' }}
      />
    );

  const value = loading
    ? loadingComponent
    : getValueStrFromSensor(mainSensor, isOn, cloudConnectivity, polyglot);
  const unit = loading ? null : getUnitStrFromSensor(mainSensor, isOn);
  const description = loading ? loadingComponent : controlUnit?.name;

  const componentCard = (
    <MerchandiseShelf
      value={value}
      unit={unit}
      variant={variant}
      error={alarmsActive.length > 0}
      description={variant === 'heated' ? description : null}
      isOn={isOn}
    />
  );

  const componentInline = (
    <Typography
      fontSize="1rem"
      color={
        alarmsActive.length > 0 ? 'error' : isOn ? 'common.black' : 'gray.main'
      }
      component={'span'}
    >
      {variant === 'heated' ? description : null} {isOn ? value : '--'}
      {isOn ? unit : null}
    </Typography>
  );

  return displayType === 'card' ? componentCard : componentInline;
}

export default MerchandiseShelfConnected;
