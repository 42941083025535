import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../store.model';
import { getAllDevicesIdsByMode } from '../../groups/selectors';
import { useDevices } from './useDevices';

function useDevicesByParams(options?: { deviceIds: string[] }) {
  const params = useParams<{
    deviceId?: string;
    groupId?: string;
    controlUnitId?: string;
  }>();
  const devicesIdsFromParams: string[] = useAppSelector((state) =>
    getAllDevicesIdsByMode(state, { params })
  );
  const devicesIds = options?.deviceIds ?? devicesIdsFromParams;
  const [devices, loading] = useDevices(devicesIds);
  return { devicesIds, devices, loading } as const;
}

export default useDevicesByParams;
