import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { MercIcon } from '../../../../../theme/icons';
import CityStoresCard from '../../../../../theme/components/CityStoresCard/CityStoresCard';
import { getPolyglot } from '../../../../../../../i18n';
import { Group } from '../../../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../../../../redux/composableDevice/model/Merchandiser.model';
import { IncidentAPIResponseMVP } from '../../../../../../../redux/incident/api/Incident.model';

const MerchandiserOverview = ({
  cityInformation,
}: {
  cityInformation: Map<
    string,
    {
      city: Group;
      stores: {
        store: Group;
        merchandisers: Merchandiser[];
        alarms: IncidentAPIResponseMVP[];
      }[];
    }
  >;
}) => {
  const polyglot = getPolyglot();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid
        item
        xs={12}
        display={'flex'}
        alignItems={'center'}
        gap="1rem"
        padding={'1rem'}
      >
        <MercIcon fontSize="1.5rem" color={theme.palette.common.black} />
        <Typography color="common.black" fontWeight={'600'}>
          {polyglot.t('freshwatch.dashboard.merchandiser_overview')}
        </Typography>
      </Grid>
      <Box
        display="grid"
        gap="1rem"
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        gridAutoRows="330px"
      >
        {[...cityInformation.keys()].map((city) => {
          const cityValue = cityInformation.get(city);
          if (!cityValue) return null;
          return (
            <CityStoresCard
              city={cityValue.city}
              key={city}
              stores={cityValue.stores}
            />
          );
        })}
      </Box>
    </>
  );
};

export default MerchandiserOverview;
