import React, { useMemo } from 'react';
import ComposableDeviceFormGeneric from './ComposableDeviceFormGeneric';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { FormikProps } from 'formik';
import { GroupCreateAPI } from '../../../redux/groups/api/group.model';
import {
  ComposableDeviceData,
  ComposableDeviceTypes,
} from '../../../redux/composableDevice/ComposableDevice.model';
import { useAppSelector } from '../../../redux/store.model';

import { IconUpload } from '../../../redux/groups/groups.model';
import ComposableDeviceFormOasis from '../../../customized/fresh-watch/components/ComposableDevice/ComposableDeviceForm/ComposableDeviceFormOasis';
import { getBluePrintFromType } from '../../../customized/fresh-watch/redux/composableDevice/selectors/composableDeviceSelectors';
export interface ComposableDeviceFormProps extends Object {
  groupNameParent?: string;
  formik: FormikProps<GroupCreateAPI & ComposableDeviceData>;
  setImage: React.Dispatch<React.SetStateAction<IconUpload | null>>;
  setLogo: React.Dispatch<React.SetStateAction<IconUpload | null>>;
  image: IconUpload | null;
  logo: IconUpload | null;
}
function ComposableDeviceForm(props: ComposableDeviceFormProps) {
  const { formik, groupNameParent } = props;
  const polyglot = getPolyglot();
  const featureToggle = useAppSelector((state) => state.featureToggle);

  const options = useMemo(
    () =>
      featureToggle.styledComponentsVariants == 'fresh-watch'
        ? // cut the first two elements of the array
          Object.keys(ComposableDeviceTypes).slice(2)
        : Object.keys(ComposableDeviceTypes),
    []
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <FormControl fullWidth>
          <InputLabel>{polyglot.t('composable_device.type')}</InputLabel>
          <Select
            label={polyglot.t('composable_device.type')}
            onChange={(event) => {
              formik.handleChange(event);
              formik.setFieldValue('type', event.target.value);
            }}
            value={formik.values.type}
            defaultValue={formik.initialValues.type}
          >
            {options.map((type) => (
              <MenuItem key={type} value={type}>
                {getBluePrintFromType(type)?.name ??
                  polyglot.t(
                    `composable_device.device_types.${type.toString()}`
                  )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ComposableDeviceFormCustom {...props} />
      </Stack>
    </form>
  );
}

function ComposableDeviceFormCustom(props: ComposableDeviceFormProps) {
  switch (props.formik.values.type) {
    case null:
    case undefined:
      return null;
    case ComposableDeviceTypes.B35R:
    case ComposableDeviceTypes.B5024RH:
      return <ComposableDeviceFormOasis {...props} />;
    default:
      return <ComposableDeviceFormGeneric {...props} />;
  }
}

export default ComposableDeviceForm;
