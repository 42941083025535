import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import getAddressesCoordinates from '../../../../../../redux/addressesCoordinates/actions';
import { getPolyglot } from '../../../../../../i18n';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import { MapComponent } from '../../../../../../components/DeviceManager/Group/Overview/MapDevices/Map';
import { useDevices } from '../../../../../../redux/devices/hooks/useDevices';
import { Device } from '../../../../../../redux/devices/api/device.model';
import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export const MapWithDevices = (props: { devicesIds: string[] }) => {
  const { devicesIds } = props;
  const [devices, loading] = useDevices(devicesIds);
  const addressesCoordinates = useAppSelector(
    (state) => state.addressesCoordinates.addressesCoordinates
  );
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const activatedLoadingGroupsDevices = useAppSelector(
    (state) => !!state.groups.activatedLoadingGroupsDevices
  );
  const polyglot = getPolyglot();
  const handleShowError = useShowErrorMessage();
  const dispatch = useAppDispatch();

  const getAddressesCoordinatesFn = (devices: Device[]) =>
    dispatch(getAddressesCoordinates(devices));

  if (loading)
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <CircularProgress />
        <Typography>{polyglot.t('group.loading_data_message')}</Typography>
      </Box>
    );
  else
    return (
      <MapComponent
        devices={devices}
        accessToken={accessToken}
        activatedLoadingGroupsDevices={activatedLoadingGroupsDevices}
        addressesCoordinates={addressesCoordinates}
        getAddressesCoordinates={getAddressesCoordinatesFn}
        polyglot={polyglot}
        handlingErrorsApi={handleShowError}
        {...props}
      />
    );
};
