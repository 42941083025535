import React, { useEffect } from 'react';
import { useShowErrorMessage } from '../../../../../handlingErrors';
import { getPolyglot } from '../../../../../i18n';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAllAvailableDevicesToAddByGroupIdSelector } from '../../../../../redux/groups/selectors';
import {
  Button,
  CircularProgress,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ErrorText } from '../../../../../theme/components/Forms';
import MultipleSelect from '../../../../../components/DeviceManager/Group/GroupSettingsPage/Incidents/MultipleSelect';
import { Base64 } from '../../../../../util/Base64';
import { getBluePrintFromType } from '../../../redux/composableDevice/selectors/composableDeviceSelectors';
import { useControlUnitsByIds } from '../../../../../redux/controlUnit/hooks/useControlUnitsByIds';
import { FormikProps } from 'formik';
import { GroupCreateAPI } from '../../../../../redux/groups/api/group.model';
import { ComposableDeviceData } from '../../../../../redux/composableDevice/ComposableDevice.model';
import { filterNotEmpty } from '../../../../../util/ArrayUtil';
import ControlUnitModel from '../../../../../redux/controlUnit/api/ControlUnitModel';
import { ZipDeviceControlUnit } from '../../../../../redux/controlUnit/controlUnit.model';
import { DeviceControlUnitsAPIResponse } from '../../../../../redux/devices/api/device.model';
import { AddIcon, DeleteIcon } from '../../../../../theme/icons';
import { ButtonIcon } from '../../../../../theme/components';
import SelectRegulatorOasisModal from './SelectRegulatorOasisModal';
import useStateBoolean from '../../../../../util/hooks/useStateBoolean';

export interface ComposableDeviceOasisFormProps extends Object {
  groupNameParent?: string;
  formik: FormikProps<GroupCreateAPI & ComposableDeviceData>;
}
function ComposableDeviceFormOasis(props: ComposableDeviceOasisFormProps) {
  const { formik } = props;
  const showError = useShowErrorMessage();

  const polyglot = getPolyglot();

  const devices = useAppSelector((state) =>
    getAllAvailableDevicesToAddByGroupIdSelector(state, {
      groupId: undefined,
    })
  );
  const devicesValue =
    typeof formik.values?.devices === 'object' ? formik.values?.devices : [];
  const composableBluePrint = getBluePrintFromType(formik.values.type);
  const nRefrigeratedShelf = parseInt(
    composableBluePrint?.taqs?.cu_groups__refrigerated ?? '0'
  );
  const nHeatedShelf = parseInt(
    composableBluePrint?.taqs?.cu_groups__heated ?? '0'
  );

  const [
    devicesControlUnits,
    devicesControlUnitsLoading,
    devicesControlUnitsError,
  ] = useControlUnitsByIds(showError, {
    deviceIds: devicesValue.map?.((item) => Base64.encode(item)) ?? [],
  });

  const cus_refrigerated = formik.values.taqs?.cus_refrigerated
    ? formik.values.taqs?.cus_refrigerated.split(',')
    : [];
  const cus_heated = formik.values.taqs?.cus_heated
    ? formik.values.taqs?.cus_heated.split(',')
    : [];

  const availableCUs = devicesControlUnits
    .map((device) =>
      device.controlUnits
        ?.filter(
          (controlUnit) =>
            // TODO: Please use another way to identify CommanderCU
            controlUnit.id != '1002'
        )
        .map((cu) => ({
          id: `${Base64.decode(device.deviceId)}/${cu.id}`,
          device,
          controlUnit: cu,
        }))
    )
    .filter(filterNotEmpty)
    .flat();

  const parsedCus_refrigerated = cus_refrigerated
    .filter(filterNotEmpty)
    .filter(Boolean)
    .map((cuDevice) => availableCUs.find((cu) => cu.id === cuDevice));
  const parsedCus_heated = cus_heated
    .filter(filterNotEmpty)
    .filter(Boolean)
    .map((cuDevice) => availableCUs.find((cu) => cu.id === cuDevice));
  useEffect(() => {
    if (
      (devicesControlUnits.length > 0, devicesControlUnitsLoading === false)
    ) {
      // Get the N first refrigerated and then the rest of heated CUs
      const refrigeratedCUs = availableCUs.slice(0, nRefrigeratedShelf);
      const heatedCUs = availableCUs.slice(
        nRefrigeratedShelf,
        nRefrigeratedShelf + nHeatedShelf
      );

      const resultRefrigeratedCUs = refrigeratedCUs
        .map((cu) => cu.id)
        .join(',');
      if (formik.values.taqs?.cus_refrigerated !== resultRefrigeratedCUs)
        formik.setFieldValue('taqs.cus_refrigerated', resultRefrigeratedCUs);
      const resultHeatedCUs = heatedCUs.map((cu) => cu.id).join(',');
      if (formik.values.taqs?.cus_heated !== resultHeatedCUs)
        formik.setFieldValue('taqs.cus_heated', resultHeatedCUs);
    }
  }, [devicesControlUnits, formik.values?.type, devicesControlUnitsLoading]);

  const onDelete = (index: number, entry: string) => {
    if (entry == 'cus_refrigerated') {
      const newCus_refrigerated = cus_refrigerated
        .filter((cu, i) => i !== index)
        .join(',');

      formik.setFieldValue('taqs.cus_refrigerated', newCus_refrigerated);
    }
    if (entry == 'cus_heated') {
      const newCus_heated = cus_heated.filter((cu, i) => i !== index).join(',');
      formik.setFieldValue('taqs.cus_heated', newCus_heated);
    }
  };

  const onAddItemRefrigerated = (cuDevice: IControlUnitDeviceOasis) => {
    const newCus_refrigerated = cus_refrigerated
      ? `${cus_refrigerated},${cuDevice.id}`
      : cuDevice.id;
    formik.setFieldValue('taqs.cus_refrigerated', newCus_refrigerated);
    closeAddItemRefrigerated();
  };
  const [
    isOpenAddItemRefrigerated,
    openAddItemRefrigerated,
    closeAddItemRefrigerated,
  ] = useStateBoolean(false);
  const showAddItemRefrigerated = Boolean(
    nRefrigeratedShelf > parsedCus_refrigerated.length && availableCUs.length
  );

  const onAddItemHeated = (cuDevice: IControlUnitDeviceOasis) => {
    const newCus_heated = cus_heated
      ? `${cus_heated},${cuDevice.id}`
      : cuDevice.id;
    formik.setFieldValue('taqs.cus_heated', newCus_heated);
    closeAddItemHeated();
  };
  const [isOpenAddItemHeated, openAddItemHeated, closeAddItemHeated] =
    useStateBoolean(false);
  const showAddItemHeated = Boolean(
    nHeatedShelf > parsedCus_heated.length && availableCUs.length
  );

  return (
    <>
      <TextField
        name="name"
        type="name"
        label={polyglot.t('composable_device.name')}
        onChange={formik.handleChange}
        value={formik.values.name}
        defaultValue={formik.initialValues.name}
        required
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={
          formik.touched.name &&
          formik.errors.name && (
            <ErrorText>{polyglot.t(formik.errors.name)}</ErrorText>
          )
        }
      />

      <TextField
        id="outlined-select-currency"
        select
        label={polyglot.t('group.devices.device')}
        value={devicesValue[0] ?? null}
        onChange={(event) => {
          formik.setFieldValue(
            'devices',
            event.target.value ? [event.target.value] : []
          );
        }}
        onBlur={formik.handleBlur}
        SelectProps={{
          id: 'devices',
          name: 'devices',
        }}
      >
        {devices.map((option) => (
          <MenuItem key={option.uuid} value={Base64.decode(option.uuid)}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      {formik.errors.devices && <ErrorText>{formik.errors.devices}</ErrorText>}
      {devicesControlUnitsLoading && <CircularProgress size={'1rem'} />}
      {nRefrigeratedShelf ? (
        <>
          <List
            aria-labelledby="nested-list-subheader"
            subheader={
              <FormLabel>
                {polyglot.t('freshwatch.dashboard.refrigerated')}
              </FormLabel>
            }
          >
            {parsedCus_refrigerated.map((cuDevice, i) => (
              <OasisRegulatorItem
                cuDevice={cuDevice}
                key={`${cuDevice?.id}-${i}`}
                onDelete={() => onDelete(i, 'cus_refrigerated')}
              />
            ))}
          </List>

          {showAddItemRefrigerated && (
            <div>
              <Button
                color="primary"
                variant="text"
                onClick={openAddItemRefrigerated}
              >
                <AddIcon />
              </Button>
              <SelectRegulatorOasisModal
                open={isOpenAddItemRefrigerated}
                onClose={closeAddItemRefrigerated}
                controlUnitList={availableCUs}
                selectRegulator={onAddItemRefrigerated}
              />
            </div>
          )}
        </>
      ) : null}

      {nHeatedShelf ? (
        <>
          <List
            aria-labelledby="nested-list-subheader"
            subheader={
              <FormLabel aria-label="nested-list-subheader">
                {polyglot.t('freshwatch.dashboard.heated')}
              </FormLabel>
            }
          >
            {parsedCus_heated.map((cuDevice, i) => (
              <OasisRegulatorItem
                cuDevice={cuDevice}
                key={`${cuDevice?.id}-${i}`}
                onDelete={() => onDelete(i, 'cus_heated')}
              />
            ))}
          </List>

          {showAddItemHeated && (
            <div>
              <Button
                color="primary"
                variant="text"
                onClick={openAddItemHeated}
              >
                <AddIcon />
              </Button>
              <SelectRegulatorOasisModal
                open={isOpenAddItemHeated}
                onClose={closeAddItemHeated}
                controlUnitList={availableCUs}
                selectRegulator={onAddItemHeated}
              />
            </div>
          )}
        </>
      ) : null}
    </>
  );
}

export interface IControlUnitDeviceOasis {
  id?: string;
  device?: ZipDeviceControlUnit;
  controlUnit?: DeviceControlUnitsAPIResponse;
}
interface ComposableDeviceFormOasisProps {
  cuDevice?: IControlUnitDeviceOasis;
  onDelete?: () => void;
}
const OasisRegulatorItem = (props: ComposableDeviceFormOasisProps) => {
  const { cuDevice, onDelete } = props;
  const polyglot = getPolyglot();
  return (
    <ListItem
      key={cuDevice?.id}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemIcon>
        {ControlUnitModel.getControlUnitIconDefault()}
      </ListItemIcon>
      <ListItemText
        primary={cuDevice?.controlUnit?.name}
        secondary={`${polyglot.t('incident_manager.titles_columns.device')}: ${
          cuDevice?.device?.deviceName
        }, ${polyglot.t('incident_manager.titles_columns.cu')}: ${
          cuDevice?.controlUnit?.id
        }`}
      />
    </ListItem>
  );
};

export default ComposableDeviceFormOasis;
