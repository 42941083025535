import React from 'react';
import {
  CardActionArea,
  CardActionAreaProps,
  Paper,
  Tooltip,
  styled,
} from '@mui/material';
import InfoCard from '../InfoCard/InfoCard';

export interface Shelf {
  description?: string;
  value: string;
  error?: boolean;
}
export interface MerchandiseInfo {
  name: string;
  shelfs?: Shelf[];
}
export interface MerchandiseProps extends CardActionAreaProps {
  merchTitle: React.ReactNode;
  subtitle: React.ReactNode;
  variant?: 'refrigerated' | 'heated';
  error?: boolean;
  children?: React.ReactNode;
  href?: string;
}
const MerchandiseCard = (props: MerchandiseProps) => {
  const {
    merchTitle: title,
    subtitle,
    error,
    variant,
    children,
    ...rest
  } = props;

  return (
    <InfoCard
      infoTitle={title}
      subtitle={subtitle}
      error={error}
      variant={variant === 'heated' ? 'big' : 'small'}
      {...rest}
    >
      <SectionContainer error={error}>{children}</SectionContainer>
    </InfoCard>
  );
};

export const SectionContainer = styled(Paper)<{
  error?: boolean;
}>(
  ({ error, theme }) => ` 
  background: ${theme.palette.background.paper};
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  border-radius: 0 0 1.5rem 1.5rem;
  position: relative;
  overflow: hidden;

  & > :first-of-type:not(:only-child)::after {
    content: '';
    position: absolute;
    background-color: ${theme.palette.text.disabled};
    width: 1px;
    height: 55%;
    bottom: 12%;
    right: 0px;
    ${
      error &&
      `
        background-color: ${theme.palette.error.main};
      
      `
    }
  }
  
`
);

export default MerchandiseCard;
