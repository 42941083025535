import React from 'react';
import { ButtonBack } from '../../../theme/components';

export interface NavBackButtonProps {
  path: {
    content: React.ReactNode;
    onClick?: () => void;
  }[];
}
function NavBackButton(props: NavBackButtonProps) {
  const { path } = props;
  const prevPath = path[path.length - 2];
  if (!prevPath) return null;
  return <ButtonBack onClick={prevPath.onClick}>{prevPath.content}</ButtonBack>;
}

export default NavBackButton;
