import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import React from 'react';
import { MercIcon } from '../../../../theme/icons';
import MerchandiseTable from '../../../../theme/components/MerchandiseTable';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import MerchandiseCardConnected from './MerchandiseCardConnected';
import { getPolyglot } from '../../../../../../i18n';
interface MerchandiseOverviewProps {
  gridDisplay: boolean;
  merchandiseItems: Merchandiser[];
}
function MerchandiseOverview(props: MerchandiseOverviewProps) {
  const { gridDisplay, merchandiseItems } = props;
  const theme = useTheme();
  const polyglot = getPolyglot();

  return (
    <>
      <Grid
        item
        xs={12}
        display={'flex'}
        alignItems={'center'}
        gap="1rem"
        padding={'1rem'}
      >
        <MercIcon fontSize="1.5rem" color={theme.palette.common.black} />
        <Typography color="common.black" fontWeight={'600'}>
          {polyglot.t('freshwatch.dashboard.merchandiser_overview')}
        </Typography>
      </Grid>
      {gridDisplay ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {merchandiseItems.length ? (
            merchandiseItems.map((merchandise) => (
              <MerchandiseCardConnected
                key={merchandise.id}
                merchandiser={merchandise}
              />
            ))
          ) : (
            <Typography>
              {polyglot.t('device.search.message_no_results_found')}
            </Typography>
          )}
        </Box>
      ) : (
        <MerchandiseTable data={merchandiseItems as any}></MerchandiseTable>
      )}
    </>
  );
}

export default MerchandiseOverview;
