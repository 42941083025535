import React from 'react';
import { useAppSelector } from '../../../../redux/store.model';
import OperatorDashboard from './Operator/OperatorDashboard';
import RegionalManagerDashboard from './RegionalManager/RegionalManagerDashboard';
import { getDashboardRightsSelector } from '../../../../redux/groups/selectors/groupRightsSelectors';
import { CircularProgress } from '@mui/material';

const DASHBOARDS = {
  SCCOperatorDashboard: <OperatorDashboard />,
  SCCRegionalDashboard: <RegionalManagerDashboard />,
};

const DashboardWrapper = () => {
  const { canViewOperatorDashboard, canViewRegionalManagerDashboard } =
    useAppSelector(getDashboardRightsSelector);

  if (canViewRegionalManagerDashboard) {
    return DASHBOARDS.SCCRegionalDashboard;
  } else if (canViewOperatorDashboard) {
    return DASHBOARDS.SCCOperatorDashboard;
  } else {
    return <CircularProgress />;
  }
};

export default DashboardWrapper;
