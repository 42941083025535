import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { ClipboardCheckIcon } from '../../icons';
export enum SEVERITY {
  MEDIUM,
  HIGH,
}
export interface Alarm extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  timestamp: number;
  task: string;
  severity: SEVERITY;
  uuid: string;
}

export const AlarmCard = (alarm: Alarm) => {
  const { title, description, timestamp, task, severity, uuid } = alarm;

  const theme = useTheme();

  return (
    <Card
      sx={{
        maxWidth: 220,
        borderRadius: '1.5rem',
        margin: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxShadow: 2,
      }}
    >
      <CardActionArea href={`/alarm/${alarm.uuid}`} sx={{ height: '100%' }}>
        <CardHeader
          sx={{
            backgroundColor: (theme) => {
              const color = severity
                ? alpha(theme.palette.error.light, 0.4)
                : theme.palette.warning.contrastText;
              return color;
            },
            flex: '1 1',
          }}
          title={
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: severity ? 'error.main' : 'warning.main' }}
            >
              {description} {title}
            </Typography>
          }
          subheader={
            <Typography
              fontSize={'0.9rem'}
              component={'span'}
              sx={{ color: severity ? 'error.main' : 'warning.main' }}
            >
              {moment(timestamp).format('l LTS')}
            </Typography>
          }
        />
        <CardContent>
          <Box
            display="flex"
            gap="0.5rem"
            justifyContent="start"
            alignItems="center"
          >
            <Box flex="1 1 20%" display="flex" alignItems="center">
              <ClipboardCheckIcon
                fill={`${theme.palette.primary.main}`}
                height={'1.2rem'}
                width={'100%'}
              />
            </Box>

            <Typography
              flex="1 1 100%"
              fontSize={'0.8rem'}
              lineHeight={1.2}
              color="text.primary"
              sx={{
                lineBreak: 'anywhere',
              }}
            >
              {task}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AlarmCard;
