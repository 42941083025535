import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  ComposableDeviceData,
  ComposableDeviceTypes,
  ST_COMPOSABLE_CONSUMPTION_DATA_KEY,
  ST_COMPOSABLE_DATA_KEY,
} from '../../redux/composableDevice/ComposableDevice.model';
import { IconUpload } from '../../redux/groups/groups.model';
import { getPolyglot } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../redux/store.model';
import useShowErrorMessage from '../../handlingErrors/useShowErrorMessage';
import { Group, GroupCreateAPI } from '../../redux/groups/api/group.model';
import * as yup from 'yup';
import { loadGroupsDevices, putGroup } from '../../redux/groups/actions/thunks';
import ComposableDeviceForm from './ComposableDeviceForm/ComposableDeviceForm';
import { getComposableDeviceByPropGroupIdSelector } from '../../redux/composableDevice/selectors/composableDeviceSelectors';
import { Base64 } from '../../util/Base64';
import { Box, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useShowMessage } from '../../util/hooks';
import { filterAndAddElements } from './Dashboard/util/mock/Utils';

interface ComposableDeviceEditProps {
  group?: Group;
}

export default function ComposableDeviceEdit({
  group,
}: ComposableDeviceEditProps) {
  const polyglot = getPolyglot();

  const dispatch = useAppDispatch();

  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();
  const [logo, setLogo] = useState<IconUpload | null>(null);
  const [image, setImage] = useState<IconUpload | null>(null);
  const composableDevice = useAppSelector((state) =>
    getComposableDeviceByPropGroupIdSelector(state, { groupId: group?.id })
  );

  const initialValues: GroupCreateAPI & ComposableDeviceData = useMemo(() => {
    setLogo({
      fileName: '',
      toolTip: '',
      file: new File([], '', {}),
      imagePreviewUrl: composableDevice?.composableDeviceData?.logoURL ?? '',
    });
    setImage({
      fileName: '',
      toolTip: '',
      file: new File([], '', {}),
      imagePreviewUrl: composableDevice?.composableDeviceData?.imageURL ?? '',
    });
    return {
      name: composableDevice?.name ?? '',
      type:
        composableDevice?.composableDeviceData?.type ??
        ComposableDeviceTypes.OVEN,
      model: composableDevice?.composableDeviceData?.model ?? '',
      description: composableDevice?.composableDeviceData?.description ?? '',
      imageURL: composableDevice?.composableDeviceData?.imageURL ?? '',
      logoURL: composableDevice?.composableDeviceData?.logoURL ?? '',
      devices:
        composableDevice?.devices?.map((item: string) => Base64.decode(item)) ??
        [],
    };
  }, [composableDevice]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .required(polyglot.t('composable_device.name_required')),
        model: yup
          .string()
          .required(polyglot.t('composable_device.model_required')),
        devices: yup
          .array()
          .of(yup.string())
          .min(1, polyglot.t('composable_device.devices_required'))
          .required(polyglot.t('composable_device.devices_required')),
      }),
    [polyglot]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const composableDeviceData: ComposableDeviceData = {
          type: values.type,
          model: values.model,
          description: values.description,
          imageURL: values.imageURL,
          logoURL: values.logoURL,
          taqs: values.taqs,
        };
        const storedValues = composableDevice?.attributes[
          ST_COMPOSABLE_CONSUMPTION_DATA_KEY
        ]
          ? JSON.parse(
              composableDevice?.attributes[
                ST_COMPOSABLE_CONSUMPTION_DATA_KEY
              ] as string
            )
          : {};
        const consumptionValues = filterAndAddElements(
          storedValues,
          values.devices?.map((item) => Base64.encode(item)) ?? []
        );
        const jsonComposableData = JSON.stringify(composableDeviceData);
        const jsonConsumptionData = JSON.stringify(consumptionValues);
        const groupdata: GroupCreateAPI = {
          name: values.name,
          attributes: {
            [ST_COMPOSABLE_DATA_KEY]: jsonComposableData,
            [ST_COMPOSABLE_CONSUMPTION_DATA_KEY]: jsonConsumptionData,
            contacts:
              composableDevice?.attributes.contacts &&
              composableDevice?.attributes.contacts.length > 0
                ? JSON.stringify(composableDevice?.attributes.contacts)
                : '',
          },
          devices: values.devices,
        };

        await dispatch(
          putGroup(
            group?.id ?? '',
            groupdata,
            logo && logo?.fileName !== '' ? logo : undefined
          )
        );
        await dispatch(loadGroupsDevices());
        showSuccess(polyglot.t('composable_device.save_successful_message'));
      } catch (error: any) {
        showError(polyglot.t('composable_device.failed_to_update'));
      }
    },
  });

  return (
    <Stack spacing={2}>
      <Typography variant="h2">
        {polyglot.t('composable_device.edit_composable_device')}
      </Typography>
      <ComposableDeviceForm
        formik={formik}
        setImage={setImage}
        setLogo={setLogo}
        image={image}
        logo={logo}
      />
      <Box
        sx={{
          textAlign: 'right',
          mt: 3,
        }}
      >
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          type="submit"
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
          startIcon={<SaveIcon />}
        >
          {polyglot.t('button.save')}
        </LoadingButton>
      </Box>
    </Stack>
  );
}
