import React from 'react';
import OverviewPanel from './Panels/OverviewPanel';
import useAllMerchandisers from '../../../redux/composableDevice/hook/useAllMerchandisers';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import {
  getAllChildrenGroups,
  getAllGroups,
} from '../../../../../redux/devices/selectors';
import {
  AccordionLight,
  AccordionSummaryLight,
} from '../../../../../theme/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography, useTheme } from '@mui/material';
import Region from './Region/Region';
import { PinIcon } from '../../../theme/icons';
import { SCC_TYPE } from '../../../redux/composableDevice/model/utils';
import {
  AlarmState,
  IncidentAPIResponseMVP,
} from '../../../../../redux/incident/api/Incident.model';
import FilterPanel from './Panels/FilterPanel';
import { Group } from '../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import { getPolyglot } from '../../../../../i18n';
import IncidentAPI from '../../../../../redux/incident/api/IncidentAPI';
import { getMyWorkspaceSelector } from '../../../../../redux/stoerkID/selectors/StoerkId.selectors';
import moment from 'moment';

const RegionalManagerDashboard = () => {
  const { merchandisers, isLoading } = useAllMerchandisers();

  const workspace = useAppSelector(getMyWorkspaceSelector);
  const [alarmsPeriod, setAlarmsPeriod] = React.useState<{
    before: number;
    after: number;
  }>({
    before: new Date().getTime(),
    after: moment().subtract(1, 'week').valueOf(),
  });
  const {
    data: { data: incidentsData = [], count: alarmsCount } = {
      data: [],
      count: 0,
    },
    error: errorIncidents,
    isLoading: isLoadingIncidents,
    isFetching: isFetchingIncidents,
  } = IncidentAPI.useQueryMyIncidentByWorkspaceIdQuery(
    {
      workspaceId: workspace?.id,
      params: {
        state: AlarmState.NEW,
        page_size: 100,
        offset: 0,
        created_after: alarmsPeriod.after,
        created_before: alarmsPeriod.before,
      },
    },
    {
      skip: !workspace?.id,
    }
  );

  const theme = useTheme();

  const groups = useAppSelector((state) => getAllGroups(state));

  const regions = groups.filter(
    (group) => group.attributes['SCC_TYPE'] === SCC_TYPE.REGION
  );
  const stores = groups.filter(
    (group) => group.attributes['SCC_TYPE'] === SCC_TYPE.STORE
  );
  const polyglot = getPolyglot();

  const timePeriods = [
    polyglot.t('freshwatch.dashboard.last_week'),
    polyglot.t('freshwatch.dashboard.last_month'),
    polyglot.t('freshwatch.dashboard.last_3_months'),
    polyglot.t('freshwatch.dashboard.last_6_months'),
    polyglot.t('freshwatch.dashboard.last_year'),
  ];
  const [filteredRegions, setFilteredRegions] =
    React.useState<Group[]>(regions);
  const [filteredStores, setFilteredStores] = React.useState<Group[]>(stores);
  const [filteredMerchandisers, setFilteredMerchandisers] =
    React.useState<Merchandiser[]>(merchandisers);

  const alarmsByRegion = new Map<string, IncidentAPIResponseMVP[]>();

  const getTimePeriod = (timePeriod: string) => {
    switch (timePeriod) {
      case timePeriods[0]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'week').valueOf(),
        };
      case timePeriods[1]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'month').valueOf(),
        };
      case timePeriods[2]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(3, 'month').valueOf(),
        };
      case timePeriods[3]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(6, 'month').valueOf(),
        };
      case timePeriods[4]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'year').valueOf(),
        };
      default:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'week').valueOf(),
        };
    }
  };

  const totalAlarms = incidentsData.filter((incident) =>
    filteredMerchandisers.find(
      (merch) => merch.deviceId === btoa(incident.deviceId)
    )
  );


  regions.forEach((region) => {
    const children = getAllChildrenGroups([region], []);

    const alarmsInRegion = totalAlarms.filter((alarm) =>
      children.find((group) =>
        group.devices.find((device) => atob(device) === alarm.deviceId)
      )
    );
    alarmsByRegion.set(region.id, alarmsInRegion);
  });
  return (
    <>
      <Grid sx={{ backgroundColor: 'common.white' }}>
        <FilterPanel
          regions={regions}
          stores={stores}
          merchandisers={merchandisers}
          timePeriods={timePeriods}
          onFilterChange={(value) => {
            setFilteredRegions(value.regions);
            setFilteredStores(value.stores);
            setFilteredMerchandisers(value.merchandisers);
            setAlarmsPeriod(getTimePeriod(value.timePeriod));
          }}
        />
      </Grid>
      <Grid container sx={{ backgroundColor: 'neutral.light', padding: '1em' }}>
        <Grid item xs={12} sm={10}>
          <OverviewPanel
            totalRegions={filteredRegions.length}
            totalStores={filteredStores.length}
            countAlarmsActive={totalAlarms.length ?? 0}
            isLoading={isLoading}
            merchandisers={filteredMerchandisers}
            isLoadingAlarmsActive={isLoadingIncidents || isFetchingIncidents}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {filteredRegions.length > 0
            ? filteredRegions.map((region) => (
                <AccordionLight
                  key={region.id}
                  sx={{ background: 'transparent' }}
                  defaultExpanded={true}
                >
                  <AccordionSummaryLight
                    id="regions-title"
                    aria-controls="accordion-regions-title"
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ padding: '1rem 0 0 0' }}
                  >
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      gap="1rem"
                      padding={'0 1rem'}
                    >
                      <PinIcon
                        fontSize="1.5rem"
                        color={theme.palette.common.black}
                      />
                      <Typography color="common.black" fontWeight={'600'}>
                        {region.name}
                      </Typography>
                    </Grid>
                  </AccordionSummaryLight>
                  <Region
                    region={region}
                    merchandisers={filteredMerchandisers}
                    stores={filteredStores}
                    alarms={alarmsByRegion.get(region.id) ?? []}
                  />
                </AccordionLight>
              ))
            : null}
        </Grid>
      </Grid>
    </>
  );
};

export default RegionalManagerDashboard;
