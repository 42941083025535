import { Box } from '@mui/material';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { IncidentAPIResponseMVP } from '../../../../../../redux/incident/api/Incident.model';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import StoreOverviewCard from '../StoreOverviewCard/StoreOverviewCard';
import React from 'react';

const StoreOverviewWrapper = (props: {
  storeWithMerchandisers: Map<
    string,
    {
      city: Group;
      stores: {
        store: Group;
        merchandisers: Merchandiser[];
        alarms: IncidentAPIResponseMVP[];
      }[];
    }
  >;
}) => {
  const { storeWithMerchandisers } = props;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {[...storeWithMerchandisers.keys()].map((storeId) => {
        const city = storeWithMerchandisers.get(storeId);
        if (!city) return null;
        const merchandisers = city.stores
          .map((store) => store.merchandisers)
          .flat();
        const alarms = city.stores.map((store) => store.alarms).flat();
        return (
          <StoreOverviewCard
            merchandisers={merchandisers}
            city={city.city}
            alarms={alarms}
            key={storeId}
          />
        );
      })}
    </Box>
  );
};

export default StoreOverviewWrapper;
