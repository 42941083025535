import React from 'react';

import { styled } from '@mui/material';

interface MerchandiseSectionProps {
  variant: 'refrigerated' | 'heated';
  error?: boolean;
  name: React.ReactNode;
  children: React.ReactNode;
}
function MerchandiseSection(props: MerchandiseSectionProps) {
  const { variant, error, name, children } = props;

  return variant == 'refrigerated' ? (
    <Section>
      <SectionName error={error} type="refrigerated">
        {name}
      </SectionName>

      {children}
    </Section>
  ) : (
    <Section>
      <SectionName error={error} type="heated">
        {name}
      </SectionName>
      <ShelfContainer>{children}</ShelfContainer>
    </Section>
  );
}

const ShelfContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

const Section = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 140px;
  position: relative;
  overflow: hidden;
`;
const SectionName = styled('div')<{
  error?: boolean;
  type: 'refrigerated' | 'heated';
}>(
  ({ type, error, theme }) => ` 
    font-size: 0.8rem;
    font-weight: bold;
    color: ${theme.palette.text.primary};
    text-align: center;
    background: ${
      type === 'heated'
        ? theme.palette.warning.light
        : theme.palette.secondary.light
    };
    padding: 0.25rem;
    ${
      error &&
      `
        background: ${theme.palette.error.light};
      `
    }
  `
);

export default MerchandiseSection;
