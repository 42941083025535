import React from 'react';
import { Grid } from '@mui/material';
import MerchandiseDetail from '../../theme/components/MerchandiseDetail/MerchandiseDetail';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store.model';
import { getMerchandiserByMerchandiserIdPropSelector } from '../../redux/composableDevice/selectors/composableDeviceSelectors';

const MerchandiseDetailPage = () => {
  const params = useParams<{ merchandiserId: string }>();
  const { merchandiserId } = params;
  const merchandiser = useAppSelector((state) =>
    getMerchandiserByMerchandiserIdPropSelector(state, {
      merchandiserId,
    })
  );

  if (!merchandiser) return null;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <MerchandiseDetail merchandiser={merchandiser} />
      </Grid>
    </Grid>
  );
};

export default MerchandiseDetailPage;
